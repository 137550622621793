@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bigcover {
        position: absolute;
        left: 0;
        z-index: 0;
        width: 100%;
        /* height: 280px; */
        overflow: hidden;
        background: #171717;
    }

    .bigcover .bigbanner {
        background-position: 50% 35%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 220px;
    }

    .details-table td {
        @apply p-1
    }

    .rating-star {
        @apply p-0 mt-2 !important
    }

    .listbox {
        @apply mr-2 mt-2
    }

    table.subtitle-table {
        width: 100%;
        max-width: 100%;
        margin: auto;
        text-align: left !important;
        border-collapse: collapse;
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse;
        table-layout: fixed
    }

    table.subtitle-table thead {
        background-color: #3f51b5;
        @apply text-base font-semibold text-white
    }

    table.subtitle-table th,
    table.subtitle-table td {
        @apply py-4;
        word-wrap: break-all;
        border-bottom: 1px solid rgba(0, 0, 0, .06);
        max-width: 100%;
    }

    .table-subtitle-info {
        width: 100%;
        max-width: 100%;
        margin: auto;
        text-align: left !important;
        border-collapse: collapse;
        text-indent: 0;
        border-color: inherit;
        border-collapse: collapse;
        table-layout: fixed
    }

    table.table-subtitle-info th,
    table.table-subtitle-info td {
        @apply p-4;
        word-break: break-word;
        border-bottom: 1px solid rgba(0, 0, 0, .06);
        max-width: 100%;
    }


    .table-zebra1-dark tbody tr:nth-child(even) td {
        --tw-bg-opacity: 1;
        @apply bg-gray-800/50
    }

    .table-zebra1 tbody tr:nth-child(even) td {
        --tw-bg-opacity: 1;
        @apply bg-gray-200/50
    }


    .scrollbar::-webkit-scrollbar {
        width: 2px;
        height: 10px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
    }

    .sc-dark::-webkit-scrollbar-track {
        @apply bg-gray-500
    }

    .sc-white::-webkit-scrollbar-track {
        @apply bg-gray-300
    }

    .scrollbar::-webkit-scrollbar-thumb {
        @apply bg-brand-blue;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        @apply bg-blue-700
    }

    .page {
        min-height: calc(100vh - 11rem)
    }

    .dark .paginate .active {
        background-color: theme('colors.pink.500') !important;
    }

    .paginate .active {
        background-color: theme('colors.brand-blue') !important;
        color: #fff !important;
    }

    .genrelist {
        position: relative;
        color: #333;
        display: block;
        padding: 5px 10px;
        margin-bottom: 1rem;
        border: 2px solid #3f51b5;
        text-align: center;
    }

    .new {
        padding: 2px 10px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 13px;
        background: #f24a4a;
        background: linear-gradient(-50deg, #ee5952, #ea3a7e, #20aadb, #23e0b3);
        background-size: 320% 200%;
        animation: Gradient 15s ease infinite;
    }

    /* override nprogress.css */
    #nprogress .bar {
        /* background: #EC4899 !important; */
        @apply bg-brand-blue important
    }

    #nprogress .peg {
        box-shadow: 0 0 0.625rem #EC4899, 0 0 0.3125rem #EC4899 !important;
    }

    #nprogress .spinner-icon {
        border-top-color: #EC4899 !important;
        border-left-color: #EC4899 !important;
    }

}